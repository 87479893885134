<template>
  <section class='section'>
    <nav class="level">
      <div class="level-left">
        <div class="level-item">
          <h1>Reclamos</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <button
            class="button"
            type="button"
            :class="{ 'is-loading': isLoading }"
            @click="getCsvDownload"
          >
            Descargar
          </button>
        </div>
      </div>
    </nav>
    <div class="columns is-multiline">
      <div class="column is-12">
        <base-table
          class="is-bordered is-striped is-narrow is-fullwidth is-hoverable bankingTable"
          :columns="columnsData"
          :data="claimsList"
          :loading="loading"
        >
        </base-table>
      </div>
    </div>
    <base-pagination
      class="is-medium"
      :page="page"
      :pages="parseInt(pages)"
      :action="paginator"
      :loading="loading"
    />
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import mixins from '../../mixin/validateDate'
const DEFAULT_DISPLAY_SIZE = 10

export default {
  name: 'Reclamos',
  mixins: [mixins],
  components: {
    BaseTable: () => import('@/components/Table'),
    BasePagination: () => import('@/components/BasePagination')
  },
  data () {
    return {
      addUserModal: false,
      modalEditAdmin: false,
      page: 1,
      pages: '',
      loading: false,
      isLoading: false,
      claimsList: [],
      columnsData: [
        {
          id: 'id', header: '#', class: 'column-mobile'
        },
        { id: 'productName',
          header: 'Producto',
          class: 'column-mobile',
          accessor: (row) => `${row.productName}`,
          type: 'link',
          link: true,
          route: (row) => `reclamos/detalle/${row.id}`
        },
        { id: 'username',
          header: 'Usuario',
          class: 'column-mobile',
          accessor: (row) => `${row.username}`,
          type: 'link',
          link: true,
          route: (row) => `usuarios/detalle/${row.userId}`
        },
        { id: 'status', header: 'Estatus', class: 'column-mobile' },
        { id: 'type', header: 'Tipo', class: 'column-mobile' },
        { id: 'created_at', header: 'Fecha', class: 'column-mobile', accessor: (row) => this.formatDateTimestamp(row.created_at) }
        // { id: 'config', name: 'config', header: 'Acciones', class: 'column-mobile has-text-centered' }
      ]
    }
  },
  methods: {
    ...mapActions(['getClaims', 'csvDownloadClaims', 'addTemporalNotification']),
    paginator (page) {
      this.page = page
      this.loading = true
      this.getClaims({ page })
        .then(resp => {
          this.loading = false
          this.claimsList = resp.payload
          this.pages = resp.totalPages
        })
    },
    getCsvDownload () {
      this.isLoading = true
      this.csvDownloadClaims()
        .then(resp => {
          if (resp) {
            const exportName = 'Export claims.csv'
            const blob = new Blob([resp], { type: 'text/csv;charset=utf-8;' })
            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(blob, exportName)
            } else {
              const link = document.createElement('a')
              if (link.download !== undefined) {
                const url = URL.createObjectURL(blob)
                link.setAttribute('href', url)
                link.setAttribute('download', exportName)
                link.style.visibility = 'hidden'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }
            }
          } else {
            this.addTemporalNotification({ type: 'danger', message: 'Error en la descarga' })
          }
          this.isLoading = false
        })
    }
  },
  beforeMount () {
    this.loading = true
    this.getClaims({ page: this.page, size: DEFAULT_DISPLAY_SIZE })
      .then(resp => {
        this.claimsList = resp.payload
        this.pages = 1
        this.loading = false
      })
  }
}
</script>
<style scoped>
  .section {
    width: 100%;
    padding: 0 20px 0 20px;
  }
  .icon-action {
    cursor: pointer;
    color: #039DA1;
  }
</style>
